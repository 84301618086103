import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

function Upload({ getInputProps, getRootProps, open }) {
  return (
    <div>
      <Container {...getRootProps()}>
        <TextContainer>
          <input {...getInputProps()} />
          <p style={{ fontFamily: "Sofia Pro Bold" }}>
            Click to upload prescription
          </p>
          <p>Take or upload an image of your prescription</p>
        </TextContainer>
      </Container>
    </div>
  );
}

const Container = styled.div`
  flex: 1;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  height: 25rem;
  border: 0.2rem dashed #0086f7;
  border-radius: 0.3rem;
  outline: none;
  margin-bottom: 2.3rem;
`;

const TextContainer = styled.div`
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 17%;
  line-height: 2.4rem;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: 30%;
    padding: 0.8rem;
  }
`;

Upload.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  getRootProps: PropTypes.func.isRequired,
  open: PropTypes.func
};
export default Upload;
