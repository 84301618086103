import { init } from "@sentry/browser";
import { APP_ENV, SENTRY_DEBUG_STATUS, SENTRY_DSN } from "./helpers/constants";
import { version } from "../package.json";

function configSentry() {
  init({
    dsn: SENTRY_DSN,
    debug: SENTRY_DEBUG_STATUS,
    environment: APP_ENV,
    attachStacktrace: true,
    release: version
  });
  return true;
}

export default configSentry();
