export const APP_URL = process.env.REACT_APP_URL;
export const BASE_NAME = process.env.REACT_APP_BASE_NAME;
export const SELF_REG_APP = process.env.REACT_APP_SELF_REG;
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const APP_ENV = process.env.REACT_APP_ENV;
export const SENTRY_DEBUG_STATUS =
  process.env.REACT_APP_SENTRY_DEBUG_STATUS === "true" ? true : false;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

export const FAILED = "failed";
export const SUCCESS = "success";

export const PER_MAX_FILE_SIZE = 5242880;

export const MOBILE_CODES = [
  {
    value: "GH",
    length: 10,
    code: "+233"
  },
  {
    value: "NG",
    length: 11,
    code: "+234"
  },
  {
    value: "ZM",
    length: 10,
    code: "+260"
  },
  {
    value: "RW",
    length: 10,
    code: "+250"
  },
  {
    value: "KE",
    length: 10,
    code: "+254"
  }
];

export const DATACORAL_API_KEY =
  process.env.REACT_APP_DATACORAL_API_KEY ||
  "o0j4EMpXeR2HmFvqVeWsS9WdrecR0XF35KRjasyh";
export const DATACORAL_ENV = process.env.REACT_APP_DATACORAL_ENV || "dev";
