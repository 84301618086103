import { MOBILE_CODES } from "./constants";

export default function validate(values) {
  let errors = {
    ...validateMuttiID(values),
    ...validateNationalID(values),
    ...validateText(values),
    ...validatePhoneNumber(values)
  };

  return errors;
}

function validateMuttiID(values) {
  let errors = {};
  const muttiIDPattern = /^\d*$/;
  const muttiIDErrorMessage = "This field can contain only numbers";
  if (values.muttiID && values.muttiID.trim() === "") {
    errors.muttiID = true;
    errors.muttiIDErrorMessage = "This field cannot be empty";
  }
  if (values.muttiID && values.muttiID.length !== 7) {
    errors.muttiID = true;
    errors.muttiIDErrorMessage = "Mutti ID must be 7 numbers";
  }
  if (values.muttiID && !muttiIDPattern.test(values.muttiID)) {
    errors.muttiID = true;
    errors.muttiIDErrorMessage = `${muttiIDErrorMessage}`;
  }
  return errors;
}

function validateNationalID(values) {
  let errors = {};
  const nationalIDPattern = /^\d*$/;
  const nationalIDErrorMessage = "This field can contain only numbers";
  if (values.nationalID && values.nationalID.trim() === "") {
    errors.nationalID = true;
    errors.nationalIDErrorMessage = "This field cannot be empty";
  }
  if (
    values.nationalID &&
    values.nationalID.trim() &&
    values.nationalID.length !== 8
  ) {
    errors.nationalID = true;
    errors.nationalIDErrorMessage = "National ID must be 8 numbers";
  }
  if (values.nationalID && !nationalIDPattern.test(values.nationalID)) {
    errors.nationalID = true;
    errors.nationalIDErrorMessage = `${nationalIDErrorMessage}`;
  }
  return errors;
}

function validateText(values) {
  let errors = {};

  const namePattern = /^[a-zA-Z]+(?:[._-][a-zA-Z]+)*$/;
  const nameErrorMessage =
    "This field can contain one of these (- ' .) between letters but not numbers";
  if (values.firstName && !namePattern.test(values.firstName)) {
    errors.firstName = true;
    errors.firstNameErrorMessage = nameErrorMessage;
  }
  if (values.firstName && values.firstName.length < 2) {
    errors.firstName = true;
    errors.firstNameErrorMessage =
      "Please enter your first name with at least 2 letters";
  }

  if (values.lastName && !namePattern.test(values.lastName)) {
    errors.lastName = true;
    errors.lastNameErrorMessage = nameErrorMessage;
  }
  if (values.lastName && values.lastName.length < 2) {
    errors.lastName = true;
    errors.lastNameErrorMessage =
      "Please enter your last name with at least 2 letters";
  }

  if (
    values.prescriberFacilityName &&
    values.prescriberFacilityName.length < 2
  ) {
    errors.prescriberFacilityName = true;
    errors.prescriberFacilityNameErrorMessage =
      "Please enter your facility name with at least 2 letters";
  }

  if (values.prescriberFullName && values.prescriberFullName.length < 2) {
    errors.prescriberFullName = true;
    errors.prescriberFullNameErrorMessage =
      "Please enter your name with at least 2 letters";
  }

  if (values.prescriptionText && values.prescriptionText.length < 2) {
    errors.prescriptionText = true;
    errors.prescriptionTextErrorMessage = "Please enter a valid prescription";
  }
  return errors;
}

function validatePhoneNumber(values) {
  let errors = {};
  const phoneNumberPattern = /^0+$/;
  const mobile = !values.mobileCode
    ? MOBILE_CODES[0]
    : MOBILE_CODES.find(item => item.value === values.mobileCode);

  if (!values.mobileCode && values.phoneNumber) {
    errors.phoneNumber = true;
    errors.phoneNumberErrorMessage = "Please select country code";
  }
  if (phoneNumberPattern.test(values.phoneNumber)) {
    errors.phoneNumber = true;
    errors.phoneNumberErrorMessage = "Phone numbers cannot be all zeros.";
  }

  if (
    mobile &&
    values.phoneNumber &&
    values.phoneNumber.length !== mobile.length
  ) {
    errors.phoneNumber = true;
    errors.phoneNumberErrorMessage = `This field must have ${mobile.length} digits`;
  }
  if (mobile && values.phoneNumber && values.phoneNumber[0] !== "0") {
    errors.phoneNumber = true;
    errors.phoneNumberErrorMessage = "This field must be prefixed with a 0";
  }
  if (mobile && values.phoneNumber && !/^\d+$/.test(values.phoneNumber)) {
    errors.phoneNumber = true;
    errors.phoneNumberErrorMessage = "Phone numbers can only be numbers.";
  }
  return errors;
}
