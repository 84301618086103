import React, { useState, useEffect, Fragment } from "react";
import styled, { keyframes } from "styled-components";
import FlowerImage from "../../images/Flower.svg";
import SmilingKidImage from "../../images/SmilingKid.jpg";
import { SELF_REG_APP } from "../../helpers/constants";
import { Mixpanel } from "../../helpers/Mixpanel";

function Home({ history }) {
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    Mixpanel.track("Visited the prescription homepage", {
      page: "prescription"
    });
  }, []);

  useEffect(() => {
    setLoadingState(currentLoadingState => currentLoadingState);
  }, [loadingState]);

  function sendPrescription() {
    Mixpanel.track("Clicked I'm an existing member", {
      page: "prescription",
      transport: "sendBeacon"
    });
    setLoadingState(true);
    setTimeout(function() {
      history.push("/send");
    }, 2000);
  }

  function selfRegistration() {
    Mixpanel.track("Clicked I'm new to mutti", {
      page: "prescription",
      transport: "sendBeacon"
    });
    setLoadingState(true);
    setTimeout(function() {
      window.location.href = SELF_REG_APP; //TODO: modify url in env and yaml when self-reg app link is ready - esp for prod
    }, 2000);
  }

  return (
    <Fragment>
      {loadingState ? (
        <div style={{ marginTop: "18rem" }}>
          <Spinner />
          <p style={{ ...loadingText, fontSize: "1.6rem" }}>Loading</p>
          <p style={{ ...loadingText, fontSize: "1.2rem" }}>
            This will take a few seconds.
          </p>
        </div>
      ) : (
        <HeadingContainer>
          <Sider>
            <img src={FlowerImage} alt="flower" style={flower} />
          </Sider>
          <Top>
            <span style={muttiText}>mutti</span>
          </Top>
          <h2 style={title}>
            Please select an
            <br /> option below to continue
          </h2>
          <div style={content}>
            <div
              style={{ ...option, background: "#FFE2D2" }}
              onClick={selfRegistration}
            >
              <div>
                <h3 style={subTitle}>I'm new to mutti</h3>
                <p style={subText}>Get registered as a Mutti member</p>
              </div>
              <div>
                <span style={arrow}>&rarr;</span>
              </div>
            </div>
            <div
              style={{ ...option, background: "#EBF3F1" }}
              onClick={sendPrescription}
            >
              <div>
                <h3 style={subTitle}>I'm an existing Mutti member</h3>
                <p style={subText}>Already registered with Mutti</p>
              </div>
              <div>
                <span style={arrow}>&rarr;</span>
              </div>
            </div>
          </div>
        </HeadingContainer>
      )}
    </Fragment>
  );
}

const Sider = styled.div`
  position: fixed;
  height: 100vh;
  width: 51rem;
  top: 0;
  left: 0;
  bottom: 0;
  background-image: url(${SmilingKidImage});
  background-size: cover;
  @media (min-width: 320px) and (max-width: 1150px) {
    display: none;
  }
`;

const Top = styled.div`
  font-size: 0.3rem;
  position: absolute;
  top: 0.3rem;
  margin-left: -11rem;
  @media (min-width: 320px) and (max-width: 1150px) {
    position: static;
    margin-left: 0;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-right: -18rem;
  margin-left: 32rem;
  @media (min-width: 320px) and (max-width: 1150px) {
    margin-right: 0;
    margin-left: 0;
  }
`;

const SpinKeyFrame = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 4px solid #fdc300;
  border-radius: 50%;
  border-top: 3px solid #b5bcc269;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  -webkit-animation: spin 2s linear infinite;
  -webkit-animation: cilQsd 2s linear infinite;
  animation: ${SpinKeyFrame} 2s linear infinite;
`;

const muttiText = {
  color: "#ea5b0c",
  fontSize: "3rem",
  fontWeight: 700
};

const loadingText = {
  color: "#000"
};

const flower = {
  width: "65%",
  bottom: "-2rem",
  position: "absolute"
};

const title = {
  color: "#000",
  fontSize: "3.8rem",
  lineHeight: "138%",
  marginBottom: "8rem",
  marginTop: "2rem"
};

const content = {
  textAlign: "left",
  marginBottom: "2rem"
};

const option = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "2.5rem 2rem 2.5rem 2rem",
  width: "100%",
  height: "10rem",
  marginBottom: "1.8rem",
  borderRadius: "1.8rem",
  cursor: "pointer"
};

const subTitle = {
  color: "#000",
  fontSize: "1.6rem"
};

const subText = {
  color: "#000",
  fontSize: "1.2rem"
};

const arrow = {
  fontSize: "2.5rem",
  fontWeight: "bold",
  color: "#000"
};

export default Home;
