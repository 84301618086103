import React from "react";
import PropTypes from "prop-types";
import Button from "mpharma-components-library/lib/Button";
import { ReactComponent as MuttiLogo } from "../images/Mutti.svg";
import { ReactComponent as HaltonsLogo } from "../images/HaltonsLogo.svg";

function Message({
  partner,
  icon,
  messageTitle,
  messageContent,
  buttonText,
  resetBodyContent
}) {
  return (
    <div style={messageContainer}>
      <div style={headingContainer}>
        {partner === "haltons" ? <HaltonsLogo /> : <MuttiLogo />}
      </div>
      <div style={content}>
        {icon}
        <h1 style={titleMessage}>{messageTitle}</h1>
        <div style={contentMessage}>{messageContent}</div>
      </div>
      <div>
        <Button
          onClick={resetBodyContent}
          secondary
          style={{
            maxWidth: "100%",
            width: "100%"
          }}
          text={buttonText}
        />
      </div>
    </div>
  );
}

const messageContainer = {
  width: "100%",
  height: "auto",
  display: "inline-block",
  margin: "0 auto"
};

const headingContainer = {
  textAlign: "center",
  marginBottom: "7rem",
  letterSpacing: ".1rem"
};

const content = {
  textAlign: "center",
  marginBottom: "2rem"
};

const titleMessage = {
  color: "#000",
  fontSize: "4rem",
  marginTop: "1.5rem"
};

const contentMessage = {
  color: "#000",
  fontSize: "2rem",
  maxWidth: "95%",
  width: "95%",
  margin: "0 auto"
};

Message.propTypes = {
  icon: PropTypes.element,
  messageTitle: PropTypes.string,
  messageContent: PropTypes.element,
  buttonText: PropTypes.string,
  resetBodyContent: PropTypes.func
};

export default Message;
