import React, { Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "mpharma-components-library/lib/Button";
import { ReactComponent as CloseIcon } from "../images/Close.svg";
import { ReactComponent as DeleteCautionIcon } from "../images/DeleteCaution.svg";
import { ReactComponent as LoadingIcon } from "../images/Loading.svg";

function Modal({
  filePreview,
  setFilePreview,
  setModalDisplayStatus,
  title,
  deleteAction,
  pageLoading,
  setDeleteAction,
  handleRemoveImage
}) {
  function setActions() {
    setModalDisplayStatus(false);
    setDeleteAction(false);
    setFilePreview(null);
  }

  return (
    <Fragment>
      {pageLoading ? (
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div>
            <LoadingIcon />
            <p style={{ color: "#fff", fontSize: "1.6rem" }}>Loading...</p>
          </div>
        </Container>
      ) : (
        <Container>
          <Content filePreview={filePreview} deleteAction={deleteAction}>
            <Top>
              <Heading>{title}</Heading>
              <Icon>
                <CloseIcon onClick={setActions} />
              </Icon>
            </Top>
            <Main>
              {filePreview && !deleteAction && (
                <img
                  style={img}
                  src={filePreview.preview}
                  alt={filePreview.name}
                />
              )}
              {filePreview && deleteAction && (
                <div style={{ textAlign: "center", color: "#000" }}>
                  <DeleteCautionIcon />
                  <h4 style={{ fontSize: "1.8rem" }}>
                    Are you sure you want to remove uploaded prescription?
                  </h4>
                  <p
                    style={{
                      fontSize: "1.6rem",
                      width: "75%",
                      display: "inline-block"
                    }}
                  >
                    You are about to remove an uploaded prescription, this will
                    remove prescription added.
                  </p>
                </div>
              )}
            </Main>
            <Bottom filePreview={filePreview} deleteAction={deleteAction}>
              {filePreview && !deleteAction && (
                <Button onClick={setActions} secondary text="Close" />
              )}
              {filePreview && deleteAction && (
                <Fragment>
                  <Button onClick={setActions} secondary text="No, cancel" />
                  <Button
                    style={{
                      background: "#FF647C",
                      border: "None",
                      marginLeft: "0.8rem"
                    }}
                    onClick={() => {
                      setActions();
                      handleRemoveImage();
                    }}
                    text="Yes, remove prescription"
                  />
                </Fragment>
              )}
            </Bottom>
          </Content>
        </Container>
      )}
    </Fragment>
  );
}

const Container = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const Content = styled.div`
  background-color: #fefefe;
  text-align: right;
  margin: 10% auto;
  padding: 2rem;
  border: 1px solid #888;
  width: ${({ filePreview, deleteAction }) =>
    filePreview && !deleteAction ? 35 : filePreview && deleteAction ? 45 : 0}%;
  height: ${({ filePreview, deleteAction }) =>
    filePreview && !deleteAction
      ? 40
      : filePreview && deleteAction
      ? 35
      : 0}rem;
  position: relative;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 1rem;
    width: 95%;
    height: ${({ filePreview, deleteAction }) =>
      filePreview && !deleteAction
        ? 40
        : filePreview && deleteAction
        ? 40
        : 0}rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 60%;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Heading = styled.div`
  font-size: 1.8rem;
  color: #000;
`;

const Icon = styled.div`
  font-size: 1.8rem;
  margin-top: -0.1rem;
  cursor: pointer;
`;

const Main = styled.div`
  margin-top: 2rem;
  height: 65%;
`;

const Bottom = styled.div`
  position: absolute;
  margin-top: 2rem;
  bottom: 2rem;
  right: 2rem;
  width: 100%;
  @media (min-width: 320px) and (max-width: 767px) {
    text-align: ${({ filePreview, deleteAction }) =>
      filePreview && !deleteAction
        ? "right"
        : filePreview && deleteAction
        ? "center"
        : "right"};
    right: 0rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

const img = {
  width: "100%",
  height: "100%",
  objectFit: "cover"
};

Modal.propTypes = {
  filePreview: PropTypes.object,
  setFilePreview: PropTypes.func,
  setModalDisplayStatus: PropTypes.func,
  title: PropTypes.string,
  deleteAction: PropTypes.bool,
  pageLoading: PropTypes.bool,
  setDeleteAction: PropTypes.func,
  handleRemoveImage: PropTypes.func
};

export default Modal;
