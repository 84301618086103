import React, { useState, useEffect, Fragment } from "react";
import { useDropzone } from "react-dropzone";
import useForm from "../../hooks/useForm";
import { MOBILE_CODES, FAILED, SUCCESS } from "../../helpers/constants";
import {
  getFilesGreaterThanFiveMB,
  compressImages,
  removeDuplicates
} from "../../helpers/utils";
import { sendPrescription as sendPrescriptionAction } from "../../api";
import Message from "../../common/Message";
import Modal from "../../common/Modal";
import Form from "./Form";
import validate from "../../helpers/validate";
import { ReactComponent as SuccessIcon } from "../../images/Correct.svg";
import { ReactComponent as FailedIcon } from "../../images/Wrong.svg";
import { ReactComponent as ClosePreviewIcon } from "../../images/ClosePreview.svg";
import { withRouter } from "react-router-dom";
import { Mixpanel } from "../../helpers/Mixpanel";

function Prescription(props) {
  const [bodyContent, setBodyContent] = useState("");
  const { values, setValues, errors, handleChange, reset } = useForm(validate);
  const [files, setFiles] = useState([]);
  const [isCompressing, setIsCompressing] = useState(false);
  const [fileUploadErrorMessages, setFileUploadErrorMessages] = useState(null);
  const [loadingButtonState, setLoadingButtonState] = useState(false);
  const [modalDisplayStatus, setModalDisplayStatus] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const partner = getPartnerFromPath();

  useEffect(() => {
    Mixpanel.track("Visited the prescription uploader form", {
      page: "prescription",
      partner
    });
  }, [partner]);

  useEffect(() => {
    setBodyContent(oldBoddyContent => oldBoddyContent);
  }, [bodyContent]);

  useEffect(() => {
    setFiles(currentFiles => currentFiles);
  }, [files]);

  useEffect(() => {
    setLoadingButtonState(
      currentLoadingButtonState => currentLoadingButtonState
    );
  }, [loadingButtonState]);

  function getPartnerFromPath() {
    const pathname = props.location.pathname;
    if (pathname.includes("physician")) {
      return "physician";
    } else if (pathname.includes("haltons")) {
      return "haltons";
    }
    return null;
  }

  const { getRootProps, open, getInputProps } = useDropzone({
    accept: ".jpg, .jpeg, .png",
    noClick: false,
    multiple: true,
    noKeyboard: true,
    disabled: isCompressing,
    onDrop: onSetFiles
  });

  function setContent(message) {
    setModalDisplayStatus(false);
    setPageLoading(false);
    setBodyContent(message);
    setLoadingButtonState(false);
  }

  async function sendPrescriptionDetail(data) {
    const response = await sendPrescriptionAction(data);
    if (!response.ok) {
      Mixpanel.track("Prescription failed to send", {
        page: "prescription",
        partner,
        first_name: data.first_name,
        country_code: data.country_code,
        phone_number: data.phone_number
      });
      setContent(FAILED);
    } else {
      Mixpanel.track("Prescription sent successfully", {
        page: "prescription",
        partner,
        first_name: data.first_name,
        country_code: data.country_code,
        phone_number: data.phone_number
      });
      setContent(SUCCESS);
    }
  }

  async function onSetFiles(uploads) {
    let fileErrors = {};
    let newFiles = [];

    if (uploads.length > 5) {
      fileErrors.FileCount = "You cannot upload more than 5 files.";
      setFileUploadErrorMessages(fileErrors);
    } else {
      setFileUploadErrorMessages(null);
      setFiles([]);
      uploads = uploads.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      const { rejectedFiles, acceptedFiles } = getFilesGreaterThanFiveMB(
        uploads
      );
      if (rejectedFiles.length > 0) {
        setIsCompressing(true);
        let file = await compressImages(rejectedFiles);
        newFiles = [...acceptedFiles, ...file];
        newFiles.length > 0 && setIsCompressing(false);
      } else {
        newFiles = [...acceptedFiles];
      }

      newFiles = newFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      let filesCombined = [...files, ...newFiles];
      filesCombined = removeDuplicates(filesCombined);

      if (filesCombined.length > 5) {
        fileErrors.FileCount = "You cannot upload more than 5 files.";
        setFileUploadErrorMessages(fileErrors);
      } else {
        setFiles(filesCombined);
      }
    }
  }

  function disablePrescriptionButton() {
    if (partner === "physician") {
      return (
        !values.firstName ||
        !values.lastName ||
        !values.phoneNumber ||
        !values.prescriptionText ||
        !values.prescriberFacilityName ||
        Object.keys(errors).length > 0
      );
    }
    return (
      !values.firstName ||
      !values.lastName ||
      !files.length > 0 ||
      !values.phoneNumber ||
      (fileUploadErrorMessages &&
        Object.keys(fileUploadErrorMessages).length > 0) ||
      Object.keys(errors).length > 0
    );
  }

  function handleSelectFlag(countryCode) {
    setValues({
      ...values,
      mobileCode: countryCode
    });
  }

  function resetBodyContent() {
    reset();
    setFiles([]);
    setBodyContent("");
  }

  function removeImage(file) {
    const newFiles = [...files];
    const fileIndex = newFiles.findIndex(item => item.name === file.name);
    newFiles.splice(fileIndex, 1);
    setFiles(newFiles);
  }

  function shouldRemoveImage() {
    setDeleteAction(true);
    setModalDisplayStatus(true);
  }

  function handleRemoveImage() {
    removeImage(filePreview);
  }

  function getPrescriptionSender(pathname) {
    switch (pathname) {
      case "/physician":
        return "F";
      case "/send":
      case "/haltons":
        return "M";
      case "/promotions":
        return "P";
      default:
        return "M";
    }
  }

  function sendPrescription() {
    setLoadingButtonState(true);
    setModalDisplayStatus(true);
    setPageLoading(true);
    const data = new FormData();
    data.append(`first_name`, values.firstName);
    data.append(`last_name`, values.lastName);
    values.muttiID && data.append(`mutti_id`, values.muttiID);
    values.nationalID && data.append(`member_national_id`, values.nationalID);
    values.prescriberFullName &&
      data.append("prescriber_full_name", values.prescriberFullName);
    values.prescriptionText &&
      data.append("prescription_text", values.prescriptionText);
    values.prescriberFacilityName &&
      data.append("prescriber_facility_name", values.prescriberFacilityName);
    data.append(
      `country_code`,
      !values.mobileCode
        ? MOBILE_CODES[0].code
        : MOBILE_CODES.find(country => country.value === values.mobileCode).code
    );
    data.append(`phone_number`, values.phoneNumber);
    data.append(
      "sender_type",
      getPrescriptionSender(props.history.location.pathname)
    );
    for (const file of files) {
      data.append(`images`, file);
    }
    sendPrescriptionDetail(data);
  }

  function showModal() {
    return (
      <Modal
        filePreview={filePreview}
        setFilePreview={setFilePreview}
        setModalDisplayStatus={setModalDisplayStatus}
        title={deleteAction ? "Remove prescription" : "View prescription"}
        deleteAction={deleteAction}
        setDeleteAction={setDeleteAction}
        pageLoading={pageLoading}
        handleRemoveImage={handleRemoveImage}
      />
    );
  }

  const thumbs = files.map(file => (
    <div
      style={thumb}
      key={file.name}
      onClick={() => {
        setModalDisplayStatus(true);
        setFilePreview(file);
      }}
    >
      <div
        style={icon}
        onClick={() => {
          setFilePreview(file);
          shouldRemoveImage();
        }}
      >
        <ClosePreviewIcon />
      </div>
      <img style={img} src={file.preview} alt={file.name} />
    </div>
  ));

  switch (bodyContent) {
    case FAILED:
      return (
        <Message
          partner={partner}
          icon={<FailedIcon />}
          messageTitle="Failed"
          messageContent={
            <p>
              <span>Your prescription was not sent to a </span>
              {partner === "haltons" ? "Haltons" : "mutti"} pharmacy.
              <br /> Please try uploading it again.
            </p>
          }
          buttonText="Try again"
          resetBodyContent={resetBodyContent}
        />
      );
    case SUCCESS:
      return (
        <Message
          partner={partner}
          icon={<SuccessIcon />}
          messageTitle="Success"
          messageContent={
            <p>
              Your prescription has been sent to a{" "}
              {partner === "haltons" ? "Haltons" : "mutti"} <br /> pharmacy for
              processing. We will contact you soon.
            </p>
          }
          buttonText="Upload a new prescription"
          resetBodyContent={resetBodyContent}
        />
      );
    default:
      return (
        <Fragment>
          <Form
            partner={partner}
            values={values}
            handleChange={handleChange}
            errors={errors}
            handleSelectFlag={handleSelectFlag}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            open={open}
            thumbs={thumbs}
            disablePrescriptionButton={disablePrescriptionButton}
            sendPrescription={sendPrescription}
            loadingButtonState={loadingButtonState}
            setPageLoading={setPageLoading}
            fileUploadErrorMessages={fileUploadErrorMessages}
            isCompressing={isCompressing}
            files={files}
          />
          {modalDisplayStatus ? showModal() : null}
        </Fragment>
      );
  }
}

const thumb = {
  display: "inline-flex",
  borderRadius: "1rem",
  border: ".1rem solid #999",
  marginBottom: "1.5rem",
  marginRight: "1.8rem",
  width: "10rem",
  height: "10rem",
  boxSizing: "border-box",
  cursor: "pointer",
  position: "relative"
};

const img = {
  display: "block",
  borderRadius: "1rem",
  width: "99.9%",
  height: "99.9%",
  objectFit: "cover"
};

const icon = {
  position: "absolute",
  top: "-1.5rem",
  right: "-1.5rem"
};

export default withRouter(Prescription);
