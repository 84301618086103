import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const useForm = validate => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors(validate(values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const reset = () => {
    setValues({});
  };

  const handleChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  return {
    handleChange,
    values,
    errors,
    reset,
    setValues
  };
};

useForm.propTypes = {
  validate: PropTypes.func.isRequired
};

export default useForm;
