import { PER_MAX_FILE_SIZE } from "./constants";
import Jimp from "jimp";

export function getFilesGreaterThanFiveMB(files) {
  let rejectedFiles = [];
  let acceptedFiles = [];
  for (let file of files) {
    file.size > PER_MAX_FILE_SIZE
      ? rejectedFiles.push(file)
      : acceptedFiles.push(file);
  }
  return { rejectedFiles, acceptedFiles };
}

export function removeDuplicates(files) {
  return files.filter(
    (file, index, self) =>
      index === self.findIndex(item => item.name === file.name)
  );
}

function dataURItoBlob(dataURI) {
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);
  let mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  let bytes = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    bytes[i] = byteString.charCodeAt(i);
  }
  return new Blob([bytes], { type: mimeString });
}

async function resizeImage(image) {
  const currentImage = await Jimp.read(image.preview);
  await currentImage
    .resize(currentImage.bitmap.width - 100, Jimp.AUTO)
    .quality(50);
  const blob = dataURItoBlob(await currentImage.getBase64Async(image.type));
  let file = new File([blob], image.name, { type: image.type });
  return file;
}

export async function compressImages(images) {
  let compressedImages = [];
  for (let image of images) {
    compressedImages.push(await resizeImage(image));
  }
  return compressedImages;
}
