import React, { useEffect } from "react";
import Message from "../../common/Message";

function RedirectToMutti() {
  useEffect(() => {
    window.location.href = "https://mymutti.com/prescriptions";
  }, []);

  return (
    <Message
      messageContent={
        <p>
          <span>Redirecting to mymutti.com/prescriptions.</span>
          <br /> Please wait...
        </p>
      }
    />
  );
}

export default RedirectToMutti;
