import mixpanel from "mixpanel-browser";
import { MIXPANEL_TOKEN } from "./constants";

mixpanel.init(MIXPANEL_TOKEN);

let __PROD__ = process.env.NODE_ENV !== "development";

let actions = {
  identify: id => {
    if (__PROD__) mixpanel.identify(id);
  },
  alias: id => {
    if (__PROD__) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (__PROD__) mixpanel.track(name, props);
  },
  people: {
    set: props => {
      if (__PROD__) mixpanel.people.set(props);
    }
  }
};

export let Mixpanel = actions;
